<template>
	<v-container>
		<base-material-card icon="mdi-basket" title="Carga / Descarga de Productos a Almacen Vendedor"
			class="px-5 py-3">
			<v-row>
				<v-col cols="12">
					<v-row>
						<v-col cols="6">
							<custom-select url="/vendedores/all" text="razon_social" label="Vendedores"
								:disabled="this.$route.params.id ? true : false"
								@change="seleccionarVendedor($event); obtenerProductosVendedor($event)"
								:error-messages="errors.vendedor_id" :valor="form.vendedor_id"></custom-select>
						</v-col>
						<v-col cols="6">
							<v-select
								:items="almacenesMovimientosTipo"
								item-text="nombre"
								item-value="id"
								v-model="form.almacenes_movimientos_tipo_id"
								:error-messages="errors.almacenes_movimientos_tipo_id"
								label="Movimiento"
								class="required"
								:disabled="undefined !== this.$route.params.id"
								@change="validarProductosTablaDetalle($event)"
							></v-select>
						</v-col>
					</v-row>
					<v-data-table v-if="0 !== productosVendedor.length" :headers="headersProductos" :items="productosVendedor"
						item-key="name" class="elevation-1 mb-10">
						<template v-slot:item.producto.precios[0].precio="{ item }">
							{{ formatoMoneda(itemPrecio(item)) }}
						</template>
						<template v-slot:item.importe="{ item }">
							{{ formatoMoneda(itemPrecio(item) * item.cantidad) }}
						</template>
					</v-data-table>

					<v-row v-if="undefined === this.$route.params.id">
						<v-col cols="6">
							<custom-select
								url="/productos"
								text="codigo_nombre"
								label="Producto"
								placeholder="Seleccione un producto"
								:valor="formProducto.producto"
								:error-messages="errors.producto"
								:disabled="'' === form.vendedor_id || null === form.almacenes_movimientos_tipo_id"
								:params="{ habilitado: 1, with_precio_has: true }"
								paginate
								@change="changeProducto"
							></custom-select>
						</v-col>
						<v-col cols="4">
							<v-text-field
								v-model="formProducto.cantidad"
								label="Cantidad"
								type="number"
								step="1"
								@keyup.enter="addProduct"
								:error-messages="errors.cantidad"
								:disabled="'' === form.vendedor_id || null === form.almacenes_movimientos_tipo_id"
							></v-text-field>
						</v-col>
						<v-col cols="2">
							<v-btn
								color="success"
								class="py-5 mt-2"
								block
								text
								small
								:disabled="'' === form.vendedor_id || null === form.almacenes_movimientos_tipo_id"
								@click="addProduct">
								<v-icon>fa fa-plus</v-icon>
							</v-btn>
						</v-col>
					</v-row>
					<custom-error :errors="errors.detalles ? errors.detalles : ''"></custom-error>
					<v-data-table :headers="headers" :items="form.detalles" item-key="name" class="elevation-1">
						<template v-slot:item.producto.nombre="{ item }">
							<span v-if="undefined !== item.valido && false === item.valido" class="red--text">
								{{ item.producto.nombre }}
							</span>
							<span v-else>{{ item.producto.nombre }}</span>
						</template>
						<template v-slot:item.precio="{ item }">
							{{ formatoMoneda(itemPrecio(item)) }}
						</template>
						<template v-slot:item.importe="{ item }">
							{{ formatoMoneda(itemPrecio(item) * item.cantidad) }}
						</template>
						<template v-slot:item.actions="{ item }">
							<v-btn v-if="!$route.params.id" style="margin-right: 5px !important" fab icon x-small
								@click="removeProduct(item)">
								<v-icon>fa fa-trash-alt</v-icon>
							</v-btn>
						</template>
						<template v-slot:body.append>
							<tr>
								<td colspan="2"></td>
								<td>Subtotal</td>
								<td class="font-weight-bold">{{ formatoMoneda(subtotal) }}</td>
							</tr>
							<tr v-for="impuesto in impuestos" :key="impuesto.nombre">
								<td colspan="2"></td>
								<td>{{ impuesto.nombre }}</td>
								<td class="font-weight-bold">
									{{ formatoMoneda(impuesto.importe) }}
								</td>
							</tr>
							<tr>
								<td colspan="2"></td>
								<td>Total</td>
								<td class="font-weight-bold">{{ formatoMoneda(total) }}</td>
							</tr>
						</template>
					</v-data-table>
				</v-col>
				<v-col cols="12">
					<v-btn
						v-if="!this.$route.params.id"
						color="primary"
						:loading="loading"
						:disabled="form.detalles.length < 1"
						@click="save()"
					>Guardar</v-btn>
					<v-btn @click="$router.push({ name: 'AlmacenesCarga' })" :loading="loading">Cancelar</v-btn>
				</v-col>
			</v-row>
		</base-material-card>
	</v-container>
</template>
<script>
import CustomSelect from "@views/dashboard/component/CustomSelect.vue";
import CustomError from '@views/dashboard/component/CustomError.vue'

const MODEL = {
	vendedor_id: "",
	detalles: [],
};

const MODEL_PRO = {
	producto_id: "",
	producto: "",
	cantidad: "",
};

export default {
	name: "AlmacenCargaProductosForm",
	components: {
		CustomSelect,
		CustomError
	},
	data: () => ({
		form: {
			vendedor_id: "",
			detalles: [],
			almacenes_movimientos_tipo_id: null,
		},
		formProducto: {
			producto_id: "",
			producto: "",
			cantidad: "",
		},
		productosVendedor: [],
		almacenesMovimientosTipo: [],
		errors: {},
		loading: false,
		headers: [
			{ text: "Cantidad", value: "cantidad", sortable: false },
			{ text: 'Código', value: 'producto.codigo', sortable: false, align:'start' },
			{ text: "Producto", value: "producto.nombre", sortable: false },
			{ text: "Precio", value: "precio", sortable: false },
			{ text: "Importe", value: "importe", sortable: false },
			{ text: "", value: "actions", sortable: false },
		],
		headersProductos: [
			{ text: "Cantidad", value: "cantidad", sortable: true },
			{ text: "Código de barras", value: "producto.codigo_barras", sortable: true },
			{ text: "Producto", value: "producto.nombre", sortable: true },
			{ text: "Precio", value: "producto.precios[0].precio", sortable: false },
			{ text: "Importe", value: "importe", sortable: false },
		],
	}),
	mounted() {
		if (this.$route.params.id) {
			this.load();
		}
		this.obtenerAlmacencesMovimientosTipo();
	},
	computed: {
		// a computed getter
		subtotal: function () {
			if (this.form.detalles && this.form.detalles.length) {
				let subtotal = this.form.detalles.reduce((acc, item) => {
					return acc + this.itemImporte(item);
				}, 0);
				return parseFloat(parseFloat(subtotal).toFixed(2));
			}
			return 0;
		},
		impuestos: function () {
			if (this.form.detalles && this.form.detalles.length) {
				let impuestosGroup = [];
				const impuestos = this.form.detalles
					// Agregando datos del producto en impuestos
					.map((item) =>
						item.producto.impuestos.map((impuesto) => {
							const importe =
								this.itemImporte(item) * (impuesto.porcentaje / 100);
							impuesto["producto"] = item.producto.nombre;
							impuesto["cantidad"] = item.cantidad;
							impuesto["producto_importe"] = this.itemImporte(item);
							impuesto["importe"] = parseFloat(parseFloat(importe).toFixed(2));
							return impuesto;
						})
					)
					// Convirtiendo datos de 2 dimensiones a 1
					.flat()
					.map((impuesto) => {
						let existeImpuesto = impuestosGroup.find((group) => {
							return group && group.nombre == impuesto.nombre;
						});
						if (existeImpuesto) {
							existeImpuesto["importe"] += impuesto["importe"];
						} else {
							impuestosGroup.push(impuesto);
						}
					});
				return impuestosGroup;
			}
			return 0;
		},
		total: function () {
			const impuestos =
				this.impuestos && this.impuestos.length
					? this.impuestos.reduce((acc, item) => {
						return acc + item.importe;
					}, 0)
					: 0;
			return this.subtotal + impuestos;
		},
	},
	methods: {
		changeProducto(item) {
			if (item) {
				this.formProducto.producto = item
			}
		},
		seleccionarVendedor(idVendedor) {
			this.form.vendedor_id = idVendedor;
		},
		obtenerAlmacencesMovimientosTipo() {
			this.$http
				.get('/almacenes-movimientos-tipo')
				.then((response) => {
					this.almacenesMovimientosTipo = response.data.data;
				})
				.catch((error) => {
					switch (error.response.status) {
						case 500:
							this.errors = this.errors = error.response.data.errors;
							break;

						default:
							break;
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		obtenerProductosVendedor(idVendedor) {
			this.loading = true;
			this.$http
				.get(`/almacenes/vendedor/${idVendedor}`, { params: { almacen_tipo: 1 } })
				.then((response) => {
					this.productosVendedor = response.data.data;
				})
				.catch((error) => {
					switch (error.response.status) {
						case 500:
							this.errors = this.errors = error.response.data.errors;
							break;

						default:
							break;
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		groupBy(array, key) {
			return array.reduce(function (r, a) {
				r[a[key]] = r[a[key]] || [];
				r[a[key]].push(a);
				return r;
			}, Object.create(null));
		},
		itemPrecio(item) {
			if (this.$route.params.id) {
				return item.precio;
			}
			return item.producto.precios[0].precio;
		},
		itemImporte(item) {
			if (this.$route.params.id) {
				return item.importe;
			}
			return this.itemPrecio(item) * item.cantidad;
		},

		validarProductoDescarga(idProducto) {
			let producto = this.productosVendedor.find(item => item.producto.id === idProducto);

			if (1 === this.form.almacenes_movimientos_tipo_id) {
				return;
			}

			if (undefined === producto) {
				this.errors = {
					producto: 'No puedes descargar este producto, no se encuentra en almacén.'
				}

				return false;
			}
			  
			if (this.formProducto.cantidad > producto.cantidad) {
				this.errors = {
					cantidad: 'La cantidad de descarga no debe ser mayor a la existencia.'
				}

				return false;
			}

			return true;
		},

		validarProductosTablaDetalle(movimientoId) {
			this.errors.detalles = null;
			let tieneErrores = false;

			if (0 === this.form.detalles.length) {
				return;
			}

			if (1 === movimientoId) {
				this.form.detalles = this.form.detalles.map((tabla) => {
					tabla['valido'] = true;
					return tabla;
				});
				return;
			}

			this.form.detalles = this.form.detalles.map((tabla) => {
				let productoAlmacen = this.productosVendedor.find(almacen => almacen.producto.id === tabla.producto.id);

				if (undefined === productoAlmacen) {
					tabla['valido'] = false;
					tabla['reason'] = 'El producto no se encuentra en almacén.';
					tieneErrores = true;

					return tabla;
				}

				if (tabla.cantidad > productoAlmacen.cantidad) {
					tabla['valido'] = false;
					tabla['reason'] = 'La cantidad que desea descargar es mayor a la existente en almacén.';
					tieneErrores = true;

					return tabla;
				}

				tabla['valido'] = true;
				tabla['reason'] = null;
				
				return tabla;
			})

			if (true === tieneErrores) {
				this.errors.detalles = 'No se puede hacer una descarga de los productos marcados.';
			}

			return tieneErrores;
		},

		validarProductoAgregardo() {
			if (0 === this.form.detalles.length) {
				return true;
			}

			let producto = this.form.detalles.find(item => item.producto.id === this.formProducto.producto.id);
			
			console.log(producto);
			if (undefined === producto) {
				return true;
			}

			const indiceProducto = this.form.detalles.indexOf(producto);

			this.form.detalles[indiceProducto]['cantidad'] = parseInt(this.form.detalles[indiceProducto]['cantidad']) + parseInt(this.formProducto.cantidad);
			this.formProducto = this.clonar(MODEL_PRO);
			
			return false;
		},

		addProduct() {
			this.errors = {};

			if (false === this.validarProductoAgregardo()) {
				return;
			}
			
			if (false === this.validarProductoDescarga(this.formProducto.producto.id)) {
				return;
			}
			
			if (this.formProducto.cantidad > 0 && this.formProducto.producto) {
				this.errors = {};
				this.form.detalles.push(this.formProducto);
				this.formProducto = this.clonar(MODEL_PRO);
			} else {
				if ('' === this.formProducto.cantidad) {
					this.errors['cantidad'] = 'Favor de agregar la cantidad de producto.';
				}
				
				if ('' === this.formProducto.producto) {
					this.errors['producto'] = 'Favor de seleccionar un producto.';
				}
			}
		},
		removeProduct(item) {
			this.form.detalles = this.form.detalles.filter((product) => {
				return product != item;
			});
		},
		load() {
			this.$http
				.get(`/almacenes/cargas/${this.$route.params.id}`)
				.then((response) => {
					this.form = response.data;
				});
		},
		save() {
			if (true === this.validarProductosTablaDetalle(this.form.almacenes_movimientos_tipo_id)) {
				return;
			}

			this.errors = {};
			this.loading = true;
			if (this.$route.params.id) {
				this.update();
				return;
			}
			this.create();
		},
		create() {
			const url = 2 === this.form.almacenes_movimientos_tipo_id ? '/almacenes/descargas-sistema' : '/almacenes/cargas';

			this.$http
				.post(url, this.form)
				.then((response) => {
					this.form = this.clonar(MODEL);
					this.$router.push({ name: "AlmacenesCarga" });
				})
				.catch((error) => {
					switch (error.response.status) {
						case 422:
							this.errors = this.errors = error.response.data.errors;
							break;

						default:
							break;
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		update() {
			this.$http
				.post(
					`/familias/${this.$route.params.id}`,
					this.toFormData(this.form, "PUT")
				)
				.then((response) => {
					this.form = this.clonar(MODEL);
					this.$router.push({ name: "AlmacenesCarga" });
				})
				.catch((error) => {
					switch (error.response.status) {
						case 422:
							this.errors = error.response.data.errors;
							break;

						default:
							break;
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		toFormData(object, method) {
			let formData = new FormData();

			if (method) {
				formData.append("_method", method);
			}

			for (const key in object) {
				if (object.hasOwnProperty(key)) {
					const element = object[key];
				}
				if (object[key]) {
					if (Array.isArray(object[key])) {
						object[key].forEach((subobject) => {
							formData.append(`${key}[]`, subobject);
						});
					} else {
						formData.append(key, object[key]);
					}
				}
			}
			return formData;
		},
	},
};
</script>
